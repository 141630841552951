<template>
  <div class="plan" >
    <div class="search-list">
      <div class="label">方案名称:</div>
      <el-input placeholder="请输入方案名称" v-model="programName" class='wd400' clearable></el-input>
      <el-button type='primary' @click='getList' class='ml20'>查询</el-button>
    </div>
    <div class="table">
      <el-table
        class='mt50'
        :data='dataList'
        style="width: 100%"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <!-- <el-table-column type='index' width='50' header-align="center" align="center"> </el-table-column> -->
        <el-table-column prop='programName' label='方案名称' header-align="center" align="center" > </el-table-column>
        <el-table-column prop='oldProgramName' label='关联发送方案名称' header-align="center" align="center" > </el-table-column>
        <el-table-column prop='createTime' label='发布日期' header-align="center" align="center" > </el-table-column>
        <!-- <el-table-column prop='viewNum' label='已查看' header-align="center" align="center" > </el-table-column> -->
        <el-table-column prop='number' label='发布人数' header-align="center" align="center" > </el-table-column>
        <!-- <el-table-column prop='publishTime' label='发布形式' header-align="center" align="center" > </el-table-column> -->
        <!-- <el-table-column prop='publishTime' label='已发布' header-align="center" align="center" > </el-table-column> -->
        <el-table-column  label='操作' header-align="center" align="center" fixed="right"> 
          <template slot-scope="scope">
            <el-button type="text" size="small" @click='goToInfo(scope.row)'>详情</el-button>
            <!-- <el-button type="text" size="small" @click='goToEdit(scope.row)'>修改</el-button> -->
            <!-- <el-button type="text" size="small" @click='del(scope.row)' style='color:#f56c6c'>删除</el-button> -->
          </template>

        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
    name:"planFeedback",
    data(){
      return {
        programName:'',
        dataList:[],
        dataListLoading:false,
        dataListSelections: [], //选中的列
        pageNo: 1,
        pageSize: 10,
        totalPage: 0,
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList(){
        this.dataListLoading = true
        let teamId = "";
        let enertyId = "";
        if (localStorage.currentTeam) {
          teamId = JSON.parse(localStorage.currentTeam).id;
          enertyId = JSON.parse(localStorage.currentTeam).entityId;
        }
        const user = JSON.parse(localStorage.getItem('userInfo'))
        const {data:res} = await this.$http({
          url:this.$http.adornUrl('/patienceProgram/getProgramInfos'),
          method:'get',
          params:{
            programName:this.programName,
            pageSize:this.pageSize,
            pageNo:this.pageNo,
            teamId,
            enertyId,
          }
        })
        if(res.status){
           res.data.forEach(v=>{
            // this.userList.forEach(user=>{
            //   if(v.createBy==user.organBusiness.id){
            //     v.creator = user.organBusiness.name
            //   }
            // })
            // this.diseasesList.forEach(v2=>{
            //   if(v.workEnertyType==v2.value){
            //     v.disease=v2.name
            //   }
            // })
            // this.typeList.forEach(type=>{
            //   if(v.noticeType==type.value){
            //     v.workTypeName=type.name
            //   }
            // })
          })
          this.dataList = res.data
            this.totalPage = res.totalCount

          }
          this.dataListLoading = false
      },
      goToInfo(row){
        this.$router.push({path:'/feedback/program/info',query:{id:row.programId,num:row.number}})
      },
      // 每页数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.pageNo = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.pageNo = val;
        this.getDataList();
      },
      // 多选
      selectionChangeHandle(val) {
        this.dataListSelections = val;
      },
    },
      
}
</script>
<style lang="scss" scoped>
 .search-list{
    display: flex;
    align-items: center;
    .label{
      width: 5%;
    }
  }
  .mt50{
    margin-top: 50px;
  }
</style>